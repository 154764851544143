import React from "react"
import Press from "../components/body/pages/press"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PressPage = () => (
  <Layout>
    <SEO
      title="Press & Media | Kuda | The Money App for Africans"
      description="For media enquiries, please contact press@kuda.com"
    />
    <Press />
  </Layout>
)

export default PressPage
