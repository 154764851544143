import React, { useEffect, Fragment } from "react"
import CTA from "../general/cta"
import TitleButton from "../general/titleButton"
import TechpointArticle from "../../../../assets/img/techpointArticle.jpg"
import TechcrunchArticle from "../../../../assets/img/techcrunchArticle.jpg"
import DisruptArticle from "../../../../assets/img/covid.png"
import TechWorldRankingArticle from "../../../../assets/img/techcrunchArticle-latest.png"
import Top32RankingArticle from "../../../../assets/img/top-32-illustration.png"
import TechCabal from "../../../../assets/img/techCabal.png"
import CBNCAfrica from "../../../../assets/img/cbncAfrica.jpg"
import Norah from "../../../../assets/img/norah.jpg"
import FintechVideo from "../../../../assets/img/fintechVideo.jpg"
import FintechFutures from "../../../../assets/img/fintechFutures.jpg"
import WeeTracker from "../../../../assets/img/weeTracker.jpg"
import Pymnts from "../../../../assets/img/pymnts.jpg"
import CNBCLaunch from "../../../../assets/img/cnbcLaunch.jpg"
import PymnytNov from "../../../../assets/img/nov/pyments-nov.jpg"
import Babs from "../../../../assets/img/nov/techcrunch-nov.jpg"
import BusinessDay from "../../../../assets/img/nov/businessDay-nov.jpg"
import DisruptNov from "../../../../assets/img/nov/disrupt-nov.jpg"
import AriseNov from "../../../../assets/img/nov/arise-nov.jpg"
import FintechInsider from "../../../../assets/img/nov/fintechInsider-nov.jpg"
import KudaOneMil from "../../../../assets/img/feb/kuda-one-mil.png"
import { scrollToElement } from "../../../utility/utils"

const pressMentions = [
  {
    publication: "Tech Crunch",
    date: "2 AUG 2023",
    title:
      "From banking giants to lending up-and-comers — here are the world’s top 200 fintech companies",
    subtitle:
      "From across the globe, spanning a diverse range of applications in finance — these are the world’s top 200 fintech companies. ",
    image: TechWorldRankingArticle,
    url: "https://www.cnbc.com/2023/08/02/here-are-the-worlds-top-200-fintechs-cnbc-and-statista.html",
    isVideo: false,
  },
  {
    publication: "Tech Crunch",
    date: "27 June 2023",
    title:
      "Unveiling the Rising Stars: Meet the 32 Scale-Up Companies Redefining Industries",
    subtitle:
      "In this Top 32 list, we highlight some of the scale-up companies that are powering through these troubled waters and forming the backbone of the economy despite the turbulent economic climate...",
    image: Top32RankingArticle,
    url: "https://www.businessleader.co.uk/rising-stars-32-scale-up-companies/",
    isVideo: false,
  },
  {
    publication: "Medium",
    date: "19 Feb 2021",
    title:
      "Africa-focused Challenger Bank Kuda Passes 1 Million Android Downloads",
    subtitle:
      "The challenger bank for Africa has gained significant traction since its beta launch in September 2019. Kuda Technologies, the digital-led challenger bank for Africa..",
    image: KudaOneMil,
    url: "https://kudabank.medium.com/press-release-africa-focused-challenger-bank-kuda-passes-1-million-android-downloads-81f2340e13b4/",
    isVideo: false,
  },
  {
    publication: "Fintech Insider",
    date: "16 Nov 2020",
    title:
      "PODCAST: WhatsApp payments in India - exponential growth potential?",
    subtitle:
      "Our expert hosts, Simon Taylor and Adam Davis are joined by some great guests to talk about the most notable fintech, financial services and banking news from the..",
    image: FintechInsider,
    url: "https://fi.11fs.com/656/",
    isVideo: true,
  },
  {
    publication: "Arise News",
    date: "12 Nov 2020",
    title:
      "VIDEO: Kuda CEO Babs Ogundeyi talks about the biggest startup seed funding to come out of Africa so far",
    subtitle:
      "kuda Microfinance Bank CEO Babs Ogundeyi spoke exclusively to the Global Business Report about the historic seed funding raise that brought in $10m..",
    image: AriseNov,
    url: "https://www.youtube.com/watch?v=58DEKaAN-dk/",
    isVideo: true,
  },
  {
    publication: "Business Insider",
    date: "11 Nov 2020",
    title:
      "Digital challenger bank ‘Kuda’ raises $10 million, the largest ever seed round in Africa",
    subtitle:
      "Kuda, Nigeria’s first fully digital bank, has announced a seed financing round of $10 million to accelerate its pan-African expansion and further develop its ethical..",
    image: Babs,
    url: "https://africa.businessinsider.com/local/markets/digital-challenger-bank-kuda-raises-dollar10-million-the-largest-ever-seed-round-in/hbz30yn/",
    isVideo: false,
  },
  {
    publication: "Disrupt Africa",
    date: "11 Nov 2020",
    title: "Nigerian digital bank Kuda raises $10m seed funding round",
    subtitle:
      "Nigerian fintech startup Kuda, which is building a pan-African digital challenger bank, has closed a US$10 million seed funding round in order to accelerate its growth..",
    image: DisruptNov,
    url: "https://disrupt-africa.com/2020/11/11/nigerian-digital-bank-kuda-raises-10m-seed-funding-round/",
    isVideo: false,
  },
  {
    publication: "Business Day",
    date: "11 Nov 2020",
    title:
      "kuda Microfinance Bank’s $10m funding could send competition in digital banking to overdrive",
    subtitle:
      "Despite a looming threat by big Nigerian banks in the space, Nigeria’s self-styled ‘Bank of the free’, kuda Microfinance Bank may be a serious contender to the digital banking throne..",
    image: BusinessDay,
    url: "https://businessday.ng/technology/article/kuda-banks-10m-funding-could-send-competition-in-digital-banking-to-overdrive/",
    isVideo: false,
  },
  {
    publication: "TechCrunch",
    date: "10 Nov 2020",
    title:
      "Nigeria’s Kuda raises $10M to be the mobile-first challenger bank for Africa",
    subtitle:
      "The African continent is currently one of the fastest-growing regions when it comes to mobile growth, and financial technology companies that are building services to..",
    image: Babs,
    url: "https://techcrunch.com/2020/11/10/nigerias-kuda-raises-10m-to-be-the-mobile-first-challenger-bank-for-africa/",
    isVideo: false,
  },
  {
    publication: "Nairametrics",
    date: "10 Nov 2020",
    title: "Nigeria’s Kuda raises Africa’s all-time seed round of $10 million",
    subtitle:
      "Kuda joins the list of other notable startups like Paystack (which was recently acquired by Stripe) and Interswitch that crossed the $1billion valuation benchmark...",
    image: Babs,
    url: "https://nairametrics.com/2020/11/10/nigerias-kuda-raise-africas-all-time-seed-round-of-10-million/",
    isVideo: false,
  },
  {
    publication: "Pymnts",
    date: "10 Nov 2020",
    title:
      "Nigeria's Challenger Bank Kuda Raises $10M For Its Mobile-First Services",
    subtitle:
      "Nigeria-based Kuda, which operates its own mobile-first challenger bank, now has $10 million after a funding round in what TechCrunch reported is the biggest..",
    image: PymnytNov,
    url: "https://www.pymnts.com/news/investment-tracker/2020/nigeria-challenger-bank-kuda-raises-10-million-dollars-mobile-first-services/",
    isVideo: false,
  },
  {
    publication: "Fintech Finance",
    date: "6 May 2020",
    title: "VIDEO: FF Virtual Arena: kuda Microfinance Bank",
    subtitle:
      "kuda Microfinance Bank's CEO Babs Ogundeyi joins our host Ali Paterson in finding out who is the #1 when it comes to the financial scene in Nigeria..",
    image: FintechVideo,
    url: "https://youtube.com/watch?v=MIFlxTJej_M/",
    isVideo: true,
  },
  {
    publication: "Fintech Finance",
    date: "21 Apr 2020",
    title:
      "Exclusive: ‘Showing the love’ – Norah Ikoh, kuda Microfinance Bank in “The Paytech Magazine”",
    subtitle:
      "Nigeria’s first mobile-only bank has seen rapid growth as it embraces the unbanked and underbanked in a country where nothing..",
    image: Norah,
    url: "https://www.fintechf.com/thought-leadership/exclusive-showing-the-love-norah-ikoh-kuda-bank-in-the-paytech-magazine/",
    isVideo: false,
  },
  {
    publication: "Disrupt Africa",
    date: "13 Apr 2020",
    title:
      "Nigerian digital banking startup Kuda launches fund to fight COVID-19",
    subtitle:
      "Nigerian digital banking startup Kuda has launched a COVID-19 fund to help buy and distribute food and other essentials to people badly affected by the economic.. ",
    image: DisruptArticle,
    url: "https://disrupt-africa.com/2020/04/nigerian-digital-banking-startup-kuda-launches-fund-to-fight-covid-19/",
    isVideo: false,
  },
  {
    publication: "CNBC Africa",
    date: "2 Apr 2020",
    title:
      "Nigeria COVID-19 lockdown: CBN calls for use of alternative payment channels",
    subtitle:
      "Its day one of the lockdown for residents of Lagos, Abuja and Ogun states since the restriction of movement announced by President Buhari. The Central Bank of Nigeria..",
    image: CBNCAfrica,
    url: "https://www.cnbcafrica.com/videos/2020/04/02/nigeria-covid-19-lockdown-cbn-calls-for-use-of-alternative-payment-channels/",
    isVideo: false,
  },
  {
    publication: "Techpoint Africa",
    date: "10 Dec 2019",
    title:
      "Kuda starts banking revolution in Nigeria with free transfers for all forever",
    subtitle:
      "Kuda, a digital-only bank licensed by the Central Bank of Nigeria, has kickstarted a revolution in Nigeria’s banking sector. Still, in beta and only three..",
    image: TechpointArticle,
    url: "https://techpoint.africa/2019/12/10/kuda-starts-banking-revolution-in-nigeria-with-free-transfers-for-all-forever/",
    isVideo: false,
  },

  {
    publication: "WeeTracker",
    date: "4 Oct 2019",
    title:
      "Kudabank: The Answer To The Problems Facing Nigeria's Banking Sector?",
    subtitle:
      "The repeatedly decried fees charged by Nigerian banks have heaped them with naerly a fortune. But Kudabank is offering a no-fee online bank.",
    image: WeeTracker,
    url: "https://weetracker.com/2019/10/04/kudabank/",
    isVideo: false,
  },
  {
    publication: "FinTech Futures",
    date: "16 Sep 2019",
    title: "Digital Nigerian bank Kuda raises $1.6m in pre-seed funding",
    subtitle:
      "Nigerian fintech start-up Kuda has raised $1.6 million in pre-seed funding in preparation for its beta launch as a digital bank",
    image: FintechFutures,
    url: "https://www.fintechfutures.com/2019/09/digital-nigerian-bank-kuda-raises-1-6m-in-pre-seed-funding/",
    isVideo: false,
  },
  {
    publication: "CNBC Africa",
    date: "13 Sep 2019",
    title:
      "Digital-only retail bank Kuda raises $1.6m, aims for Q4 live launch in Nigeria",
    subtitle:
      "Lagos and London-based digital-only retail bank, Kuda says it has raised $1.6 million in pre-seed funding. To discuss their plans going forward..",
    image: CNBCLaunch,
    url: "https://www.cnbcafrica.com/videos/2019/09/13/digital-only-retail-bank-kuda-raises-1-6mn-aims-for-q4-live-launch-in-nigeria/",
    isVideo: false,
  },

  {
    publication: "TechCabal",
    date: "12 Sep 2019",
    title:
      "Nigerian digital bank, Kuda, raises $1.6 Million pre-seed funding in readiness to exit beta",
    subtitle:
      "And now, three years later, the rebranded fully-fledged online-only digital bank, Kuda, has raised pre-seed funding to the tune of $1.6 million from a number of angel..",
    image: TechCabal,
    url: "https://techcabal.com/2019/09/12/nigerian-digital-bank-kuda-raises-1-6-million-pre-seed-funding-in-readiness-to-exit-beta/",
    isVideo: false,
  },
  {
    publication: "TechCrunch",
    date: "11 Sep 2019",
    title: "Nigerian online-only bank startup Kuda raises $1.6M",
    subtitle:
      "Nigerian fintech startup Kuda — a digital-only retail bank — has raised $1.6 million in pre-seed funding. The Lagos and London-based company recently launched the beta..",
    image: TechcrunchArticle,
    url: "https://techcrunch.com/2019/09/10/nigerian-online-only-bank-startup-kuda-raises-1-6m/?guccounter=1/",
  },
  {
    publication: "PYMNTS.com",
    date: "11 Sep 2019",
    title: "Nigerian FinTech Kuda Raises $1.6M",
    subtitle:
      "Kuda, Nigeria's first digital-only bank with a standalone license, has raised $1.6 million in pre-seed funding led by Haresh Aswani.",
    image: Pymnts,
    url: "https://www.pymnts.com/news/investment-tracker/2019/nigerian-fintech-kuda-funding-digital-banking/",
    isVideo: false,
  },
]

const Press = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <div className="kuda-inner--heading">
        <div className="kuda-section--inner">
          <div className="kuda-section--100 inner-spotlight--heading text-center has-heading--divider">
            <h1 className="text-xl text-xlbold color-primary title-bottom--spacing">
              Press
            </h1>
            <TitleButton
              text={
                <span>
                  For media enquiries, please contact{" "}
                  <a href="mailto:press@kuda.com" className="color-primary">
                    press@kuda.com
                  </a>
                </span>
              }
              button="Download Press Kit"
              url="https://drive.google.com/folderview?id=1rJ1PL3TerzvhBomiFUqMJ40i11m0ASo8"
              isExternal={true}
            />
          </div>
          <div className="kuda-section--100 kuda-spacing--inner kuda-disclaimer--section">
            <h1 className="kuda-section--heading text-left text-xlbold color-primary bottom--spacing">
              Media Mentions
            </h1>
            <div className="kuda-press--cards flex justify-between flex-wrap">
              {pressMentions.map((item, i) => (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={i}
                  className="kuda-single--press"
                >
                  <div className="kuda-press--image">
                    <img src={item.image} alt="kuda Press Mentions" />
                  </div>
                  <div className="kuda-press--wrap">
                    <div className="kuda-press--info flex justify-between align-center">
                      <div className="kuda-press--publication">
                        {item.publication}
                      </div>
                      <div className="kuda-press--date">{item.date}</div>
                    </div>
                    <div className="kuda-press--details">
                      <h2 className="text-xlbold color-primary title-bottom--spacing kuda-press--heading">
                        {item.title}
                      </h2>
                      <p className="color-black kuda-press--subheading">
                        {item.subtitle}
                      </p>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <CTA />
    </Fragment>
  )
}

export default Press
